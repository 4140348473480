import { Controller } from "stimulus"
import js_cookie from "js-cookie"
import MicroModal from "micromodal"

export default class extends Controller {
    // Makes this.contentTarget available to methods
    static targets = ["content", "nextButton"]

    static values = { show: Boolean }

    // Stimulus lifecycle method fired when controller is mounted on the DOM.
    connect() {
        if (!js_cookie.get("has-seen-wizard-modal")) {
            MicroModal.show('wizard-modal')
            js_cookie.set(`has-seen-wizard-modal`, true)
        } else if (this.showValue === true) {
            MicroModal.show('wizard-modal')
        }
    }

    // Replaces the content of the wizard with the next content a user should see
    next() {
        this.#fetchContent("/wizard/next")
    }

    // Replaces the modal content with the registration step
    registrationStep() {
        this.#fetchContent("/wizard/registration-step")
    }

    // Replaces the modal content with organization step
    organizationStep() {
        this.#fetchContent("/wizard/organization-step")
    }

    // Replaces the modal content with step two
    locationsStep() {
        this.#fetchContent("/wizard/locations-step")
    }

    // Replaces the modal content with review
    review() {
        this.#fetchContent("/wizard/review")
    }

    // Replaces the modal content with finish
    finish() {
        this.#fetchContent("/wizard/finish")
    }

    // Ensure location is syncable before enabling next button
    checkLocationSyncable(e) {
        if (JSON.parse(e.detail[2].response).syncable) {
            this.enableNextButton()
        } else {
            this.disableNextButton()
        }
    }

    // Enables the next button
    enableNextButton() {
        this.nextButtonTarget.removeAttribute("disabled")
    }

    // Disables the next button
    disableNextButton() {
        this.nextButtonTarget.setAttribute("disabled", "disabled")
    }

    // Private method replacing the content's HTML with the body returned from the path
    #fetchContent(path) {
        fetch(path)
            .then(response => response.text())
            .then(html => this.contentTarget.innerHTML = html)
    }
}
