import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["form"]
    static values = { locationId: String }

    update() {
        this.#fetchContent(`/locations/${this.locationIdValue}/edit_form`)
    }

    // Private method replacing the content's HTML with the body returned from the path
    #fetchContent(path) {
        fetch(path)
            .then(response => response.text())
            .then(html => this.formTarget.innerHTML = html)
    }
}
