import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["label"]

    update() {
        if (this.hasLabelTarget) {
            this.labelTarget.textContent = this.labelTarget.textContent.trim() === "Active" ? "Inactive" : "Active"
        }
    }
}
